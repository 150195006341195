import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';


export const BecomeATenent = () => {
    return (
        <>
        <KWNavbar></KWNavbar>
        <div className="container mt-5 pt-5">
            {/* ********  Blank Page  ******** */}
            <h1 style={{textAlign: "center"}} className='mt-5' >Become A Tenent</h1>
        </div>
        <KWFooter></KWFooter>
        </>
    )
}
import React, { useEffect } from "react";
import KWNavbar from "../../components/navbar/navbar";
import KWFooter from "../../components/footer/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Carousel from 'react-bootstrap/Carousel';
import "./listing.css";
import samplePic from '../../assets/no-image.png'
import camera from '../../assets/icons8-compact-camera-50.png'


const Listing = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const location = useLocation();
  const listing = location.state.listing;
  const dateTime = location.state.dateTime;
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, []);
  const handleNavigate = () => {
    // dateTime == undefined? navigate(`/ScheduleShowing/pid/${listing.Unit.Id}`)
    // :
    // navigate(`/ConfirmProperty/${dateTime.eventId}`, { state: { bookData: dateTime } })
    navigate(`/ScheduleShowing/pid/${listing.Unit.Id}`)
  }
  console.log(listing);
  return (
    <div>
      <KWNavbar />
      <Container className="p-5">
        <Row className="pt-5 mt-5">
          <Col sm={12} lg={6}>
            <h2>{listing?.Property.Name}</h2>
            <h5>K-W Property Management Corp Presents:</h5>
            <h5 className="fw-normal mt-4">
              Located at {listing?.Unit.Address.AddressLine1}
            </h5>
            <h5 className="fw-normal mt-4">
              {listing?.Property.StructureDescription}
            </h5>
            <h5 className="fw-normal mt-4">
              To book an appointment check our online calendar for upcoming
              showings{" "}
              <a href="http://kwproperty.com/calendar.asp">
                here
              </a>
              . If you do not find a suitable showing time, please email us your
              availability and we will book you a time to see the unit.
            </h5>
            <h3 className="blue mt-5">${listing.Rent}/mo</h3>
            <Button className="btn btn-primary d-block mt-4 button-c">
              Apply Online Today
            </Button>
            <Button
              onClick={handleNavigate}
              className="btn btn-primary d-block mt-4 button-c">
              Book Appointment
            </Button>
          </Col>
          <Col sm={12} lg={6} className="ps-lg-5 mt-5 mt-lg-0">
            <h5>Available: {listing.AvailableDate}</h5>
            <Image
              src={listing.Unit.Files[0] ? listing.Unit.Files[0]?.Url : samplePic}
              className="image-container mb-4 mb-lg-0"
              fluid
            />
            <button
            onClick={() => setModalShow(true)}
            className="px-5 py-2 seePhotoBtn">
              <img className="p-1" src={camera} color="blue" alt="" width={30}/>
              See all photos</button>
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="center h-50 ">
            <Carousel>
              {
                listing.Unit.Files.length > 0 ? (
                  listing.Unit.Files.map((img ,index) => {
                    return (
                      <Carousel.Item key={index}>
                        <Image
                          src={img.Url}
                          className="image-containers mb-4 mb-lg-0"
                          fluid
                        />
                        <Carousel.Caption>
                          {/* <h3 className="tedxtblue">{listing?.Property.Name}</h3> */}
                        </Carousel.Caption>
                      </Carousel.Item>
                    )
                  })

                ) : (
                  <Carousel.Item>
                    <Image
                      onClick={() => setModalShow(true)}
                      src={listing.Unit.Files[0] ? listing.Unit.Files[0]?.Url : samplePic}
                      className="image-container mb-4 mb-lg-0"
                      fluid
                    />
                    <Carousel.Caption>
                      {/* <h3 className="tedxtblue">{listing?.Property.Name}</h3> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                )
              }
            </Carousel>
          </div>
        </Modal.Body>
      </Modal>
      <KWFooter />

    </div>
  );
};

export default Listing;

import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import ReCAPTCHA from 'react-google-recaptcha';

import './ServiceRequest.css';


export const ServiceRequest = () => {
    const handleCaptcha = (val) => {
        console.log(val)
    }
    return (
        <>
            <KWNavbar></KWNavbar>

            <div className="serviceRequest-container mt-5 pt-5">
                <div className="mt-5">
                    <div className="service-header">Work Order Request Form</div>
                    <div className="service-para">
                        Please fill out the work order request form and we will respond to your needs quickly and efficiently. If you have any questions or concerns regarding your work order, please don't hesitate to contact us through the means below.
                    </div>
                    <div className="service-H_details">
                        <div className="">Phone : 519-954-8082</div>
                        <div className="">Email : <a href="">maintenance@kwproperty.com</a></div>
                    </div>
                    <div className="service-form">
                        <div className='border rounded p-3 mt-3'>
                            <div
                                className='fs-4 justify-content-between d-flex'
                                data-bs-toggle="collapse"
                                data-bs-target="#Unitcontactinformation"
                                style={{ cursor: "pointer" }}
                            >
                                <div><b className='service-form-para2-H'>Unit & contact information</b></div>
                                <div className="">
                                    <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                                </div>
                            </div>
                            <div className="collapse" id="Unitcontactinformation">
                                <div className="input-div">
                                    <label className='service-form-label' htmlFor="YourName">Your Name*</label>
                                    <input className='service-form-input required-number required-number2' type="text" name="YourName" id="YourName" />
                                </div>
                                <div className="input-div">
                                    <label className='service-form-label' htmlFor="YourName">Your Address*</label>
                                    <input className='service-form-input required-number required-number2' type="text" name="YourName" id="YourName" />
                                </div>
                                <div className="input-div">
                                    <label className='service-form-label' htmlFor="YourName">Unit Number*</label>
                                    <input className='service-form-input required-number required-number2' type="text" name="YourName" id="YourName" />
                                    <br />
                                    <div className="checkbox-div">
                                        <input className='service-form-checkbox required-number required-number2' type="checkbox" name="" id="" />
                                        <div className='service-form-label'>I don't have a unit number (e.g. Single Family Home)</div>
                                    </div>
                                </div>
                                <div className="input-div">
                                    <label className='service-form-label' htmlFor="YourName">City*</label>
                                    <input className='service-form-input required-number required-number2' type="text" name="YourName" id="YourName" />
                                </div>
                                <div className="input-div">
                                    <label className='service-form-label' htmlFor="YourName">Phone*</label>
                                    <input className='service-form-input required-number required-number2' type="text" name="YourName" id="YourName" />
                                </div>
                                <div className="input-div">
                                    <label className='service-form-label' htmlFor="YourName">Email Address*</label>
                                    <input className='service-form-input form-control required-number required-number2' type="text" name="YourName" id="YourName" />
                                </div>
                                <div className="input-div">
                                    <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                    <div className="select-div">
                                        <div className='service-form-label2'>Preferred method of contact?</div>
                                        <select name="" id="" className='service-form-input required-number required-number2'>
                                            <option value="">Please Select</option>
                                            <option value="">Call</option>
                                            <option value="">Text</option>
                                            <option value="">Email</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='border rounded p-3 mt-3'>
                            <div
                                className='fs-4 justify-content-between d-flex'
                                data-bs-toggle="collapse"
                                data-bs-target="#Servicerequired"
                                style={{ cursor: "pointer" }}
                            >
                                <div><b>Work / Service required</b></div>
                                <div className="">
                                    <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                                </div>
                            </div>
                            <div className="collapse" id="Servicerequired">
                                <div className="">
                                    <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                    <div className="select-div">
                                        <div className='mb-2 mt-2'><b>Please describe the work or service required:</b></div>
                                        <div className="PleaseDescribe">
                                            If you're dealing with an <b style={{ color: "#0c5460" }}>appliance issue</b> please specify the <b style={{ color: "#0c5460" }}>make</b> and <b style={{ color: "#0c5460" }}>model</b> so we can better serve you.
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                    <div>
                                        <textarea className='service-form-input required-number required-number2' name="" id="" rows="7" maxLength="250"></textarea>
                                        <br />
                                        <small style={{ opacity: 0.6 }}>250 characters remaining</small>
                                    </div>
                                </div>
                                <div className="">
                                    <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                    <div>
                                        <div className='service-form-label2'>Requested date(s)/time(s) for service call:</div>
                                        <textarea className='service-form-input required-number required-number2' name="" id="" rows="7" maxLength="250"></textarea>
                                        <br />
                                        <small style={{ opacity: 0.6 }}>250 characters remaining</small>
                                    </div>
                                </div>
                                <div className="">
                                    <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                    <div>
                                        <div className='service-form-label2'>Do you need to be home for this service call?</div>
                                        <div className="d-flex">
                                            <div className="d-flex me-2">
                                                <input type="radio" name="" id="" /><div className='ms-2 mt-2 service-form-label2'>Yes</div>
                                            </div>
                                            <div className="d-flex">
                                                <input type="radio" name="" id="" /><div className='ms-2 mt-2 service-form-label2'>No</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div class="form-group">
                                        <div class="custom-file mt-2">
                                            {/* <input type="file" class="custom-file-input" id="customFile"> */}
                                            <input className='custom-file-input' type="file" name="customFile" id="customFile" />
                                            <label class="custom-file-label" for="customFile">Drop photos here or click to upload</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                    <div className="PleaseDescribe">
                                        File must be an image (.jpg, .png, .bmp, etc) and no larger than 5 MB in size. We currently limit number of images to a <b style={{ color: "#0c5460" }}>maximum of 5 images.</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border rounded p-3 mt-3">
                            <div
                                className='fs-4 justify-content-between d-flex'
                                data-bs-toggle="collapse"
                                data-bs-target="#TermsandConditions"
                                style={{ cursor: "pointer" }}
                            >
                                <div><b className='service-form-para2-H'>Terms and Conditions</b></div>
                                <div className="">
                                    <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                                </div>
                            </div>
                            <div className="collapse" id="TermsandConditions">
                                <div className="">
                                    <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                    <div className="service-form-para2">
                                        <div className="service-form-para2-text">
                                            Tenants please be advised you will be charged for a service call if the following work order is a result of tenant neglect or damage. Also including below but not limited to:
                                        </div>
                                        <div className="service-form-para2-text">
                                            <b>CLOGGED SINKS -</b> This service call is a charge to the tenants if the pipes are full of debris. The tenant will not be charged if the pipe is structurally compromised
                                        </div>
                                        <div className="service-form-para2-text">
                                            <b>NO HYDRO -</b> The tenant will be charged for this service call if the breakers are off or fuses are burnt out. The tenant will also be charged for this service call if the receptacle reset button needs to be pressed to reset. Please ensure you have checked breakers, fuses and reset buttons prior to our company sending out an electrician. This will avoid costs to you.
                                        </div>
                                        <div className="service-form-para2-text">
                                            FURNACE NOT WORKING - Please make sure the outside vent (exhaust vent) is not clogged by debris or ice or snow. If the HVAC technician arrives on site and determines the cause to be a blocked vent, the tenant will be held responsible for the service call. The tenant will also be held responsible for the service call if the batteries on the thermostat are low, or a dirty furnace prevented the furnace from working (Furnace filters should be changed at least every 3 months).
                                            <b>
                                                Make sure Furnace and or AC switch is turned on, or the tenant will be held responsible for the service call.
                                            </b>
                                        </div>
                                        <div className="service-form-para2-text">
                                            <b>LIGHT BULBS -</b> The tenant is responsible for changing all burnt out light bulbs.
                                        </div>
                                        <div className="service-form-para2-text">
                                            <b>NO ACCESS GRANTED -</b> Please be sure if you request to be home during the service call you are in fact there. If you are not there to grant access after you specifically ask for this service, you will be charged for the service call.
                                        </div>
                                        <div className="service-form-para2-text">
                                            <b>WATER SOFTENER -</b> If your work order is in regards to your water softener running continuously, please put the water softener on bypass by either turning the valve manually or on the program screen selecting bypass. If you cannot determine the bypass please call our office immediately.
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 ps-2">
                            <div className='mb-3'>
                                <div className='service-form-label2'>I accept these terms and conditions:</div>
                                <div className="mt-2 d-flex">
                                    <div class="form-check me-3">
                                        <input type="radio" class="form-check-input" id="radio1" name="optradio" value="option1" style={{cursor: "pointer"}} />
                                        <label class="form-check-label" for="radio1" style={{cursor: "pointer"}}>Yes</label>
                                    </div>
                                    <div class="form-check ms-2">
                                        <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2" style={{cursor: "pointer"}} />
                                        <label class="form-check-label" for="radio2" style={{cursor: "pointer"}}>No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="service-form-label2 mb-2">To protect this website from spam, please check the box below.</div>
                            <div>
                                <ReCAPTCHA
                                    sitekey='6LdnSbYoAAAAABSGB3Ns7SgyQK2Nvn9dODuhzTR4'
                                    onChange={handleCaptcha}
                                />
                            </div>
                            <div className="mt-3">
                                <button type='button' className='Property-Currently-button'>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <KWFooter></KWFooter>
        </>
    )
}
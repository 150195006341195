import React, { useEffect, useState } from 'react'
import { Collapse, Row } from 'react-bootstrap'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import img from "./download.png"
import Form from 'react-bootstrap/Form';
import config from '../../config.json';
import swal from 'sweetalert'
import './GeneralApllication.css'
import { RotatingLines } from 'react-loader-spinner';

export const GeneralApllication = () => {
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [youViewedisOn, setyouViewedisOn] = useState(false);
    const [birthYear, setBirthYear] = useState()
    const [birthMonth, setBirthMonth] = useState()
    const [birthDay, setBirthDay] = useState()
    let date = new Date();
    const year = function () {
        const yy = [];
        for (let y = date.getFullYear(); y >= 1904; y--) {
            yy.push(y);
        }
        return yy
    }
    const day = function () {
        const dy = [];
        for (var i = 1; i <= 31; i++) {
            dy.push(i);
        }
        return dy
    }
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const data = year();
    const d = day();
    
    const [formData, setFormData] = useState({
        preferredMoveInDate: "",
        propertyAddress: "",
        offeredRentAmount: 0,
        haveYouViewedTheUnit: "",
        requiredNumberOfParkingSpaces: 0,
        familySizeToOccupyHomeAdults: 0,
        familySizeToOccupyHomeChildrens: 0,
        doYouOwnAnyPets: youViewedisOn,
        currentLocation: "",
        previousAddress: "",
        previousLandlordsPhone: "",
        previousLandlordsFirstName: "",
        previousLandlordsLastName: "",
        previousLandlordsEmailAddress: "",
        coSigner: null,
        recipientEmail: config.emails.application.general.to,
        additionalExpectations: "",
        cc: config.emails.application.general.cc,
        recipientName: "",
        phone: "",
        mailSubject: config.emails.application.general.subject,
        templateData: {
            "message": "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
            "link1": "<link>"
        }
    })
    const [personalInfo, setPersonalInfo] = useState(false)
    const [importantNote1, setimportantNote1Info] = useState(false)
    // const [importantNote2, setimportantNote2Info] = useState(false)
    const [RHCL, setRHCL] = useState(false)
    const [RHPL, setRHPL] = useState(false)
    const [CoSigner, setCoSigner] = useState(false)
    const [Other, setOther] = useState(false)

    const [isOfferedRentAmountChecked, setisOfferedRentAmountchecked] = useState(false);

    const handleOfferedRentAmountSwitchChange = () => {
        setisOfferedRentAmountchecked(!isOfferedRentAmountChecked);
        setyouViewedisOn(!youViewedisOn);
    };
    const [therebeisOn, settherebeisOn] = useState(false);
    const [isWillThereBeaCosignerChecked, setisWillThereBeaCosignerchecked] = useState(false);
    const [isDoyouOwnAnyChecked, setisDoyouOwnAnychecked] = useState(false);
    useEffect(() => {
        formData.doYouOwnAnyPets = isOfferedRentAmountChecked
        formData.haveYouViewedTheUnit = isDoyouOwnAnyChecked
        formData.coSigner = isWillThereBeaCosignerChecked
    }, [youViewedisOn, isDoyouOwnAnyChecked, isOfferedRentAmountChecked, isWillThereBeaCosignerChecked, therebeisOn])
    useEffect(() => {
        formData.dateOfBirth = `${birthDay}-${birthMonth}-${birthYear}`
    }, [birthDay, birthMonth, birthYear])
    const [youOwnisOn, setyouOwnisOn] = useState(false);

    const handleDoyouOwnAnySwitchChange = () => {
        setisDoyouOwnAnychecked(!isDoyouOwnAnyChecked);
        setyouOwnisOn(!youOwnisOn);
    };

    const handleWillThereBeaCosignerSwitchChange = () => {
        setisWillThereBeaCosignerchecked(!isWillThereBeaCosignerChecked);
        settherebeisOn(!therebeisOn);
    };
    // const [isAdditionalExpectationsChecked, setisAdditionalExpectationschecked] = useState(false);

    // const handleAdditionalExpectationsSwitchChange = () => {
    //     setisAdditionalExpectationschecked(!isAdditionalExpectationsChecked);
    // };

    const handleCollapse = (from) => {
        if (from === 'importantNote1') {
            setimportantNote1Info(!importantNote1);
        }
        // if (from === 'importantNote2') {
        //     setimportantNote2Info(!importantNote2);
        // }
        if (from === 'personalInfo') {
            setPersonalInfo(!personalInfo);
        }
        if (from === 'RHCL') {
            setRHCL(!RHCL)
        }
        if (from === 'RHPL') {
            setRHPL(!RHPL)
        }
        if (from === 'Co-Signer') {
            setCoSigner(!CoSigner)
        }
        if (from === 'Other') {
            setOther(!Other)
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }
    const handleSubmit = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(formData);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(config.apiUrl + "/v1/application/general", requestOptions)
            .then(res => res.json())
            .then((data) => {
                if (data.status === 200) {
                    setFormData({
                        preferredMoveInDate: "",
                        propertyAddress: "",
                        offeredRentAmount: 0,
                        haveYouViewedTheUnit: "",
                        requiredNumberOfParkingSpaces: 0,
                        familySizeToOccupyHomeAdults: 0,
                        familySizeToOccupyHomeChildrens: 0,
                        doYouOwnAnyPets: youViewedisOn,
                        currentLocation: "",
                        previousAddress: "",
                        previousLandlordsPhone: "",
                        previousLandlordsFirstName: "",
                        previousLandlordsLastName: "",
                        previousLandlordsEmailAddress: "",
                        coSigner: null,
                        recipientEmail: config.emails.application.general.to,
                        additionalExpectations: "",
                        cc: config.emails.application.general.cc,
                        recipientName: "",
                        phone: "",
                        mailSubject: config.emails.application.general.subject,
                        templateData: {
                            "message": "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
                            "link1": "<link>"
                        }
                    })
                    setIsSubmiting(false)
                    swal(data.res, data.res, "success");
                } else if (data.status === 401) {
                    setIsSubmiting(false)
                    swal(data.res, data.res, "error");
                }
                else {
                    setIsSubmiting(false)
                    swal(data.res, data.res, "error");
                }
            })
            .catch(error => console.error(error))
    }

    return (
        <>
            <>
                {
                    isSubmiting ? (
                        <div style={{ position: "fixed", width: "100%", height: "100vh", background: "rgba(0,0,0,0.3)", opacity: "1000" }}
                            className="d-flex justify-content-center"
                        >
                            <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"
                                visible={true}
                            />

                        </div>
                    ) : (
                        <>
                            <KWNavbar />
                            <div style={{ backgroundColor: "#eee" }} className='h-auto mt-4'>
                                <div className='mt-2 pt-5 container'>
                                    <Row>
                                        <div className='col-12 justify-content-center align-items-center m-auto student-main-margin' >
                                            <br />
                                            {/* <div
                                    style={{ border: "2px solid #337ab7" }}
                                    className="row m-1 d-flex rounded p-3 bg-white">
                                    <p className='p-1'>
                                        <img style={{ width: "15px" }} src={img} alt='' />
                                        <span
                                            style={{ color: "#333", fontWeight: 'bold', lineHeight: 1.42857143, marginBottom: 5, }}
                                        >To speed up the application process, we require every applicant that is over 18 years old to provide a full credit report, reflecting the score. Please see below for some free & paid websites to pull your own credit report and self-submit. Thank-you, The Management.</span>
                                    </p>
                                    <div className='row text-decoration-none' >
                                        <div className='col-xs-6 col-md-4'><a href="https://www.creditkarma.ca/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.creditkarma.ca</a></div>
                                        <div className='col-xs-6 col-md-4'><a href="https://borrowell.com/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.borrowell.com</a></div>
                                        <div className='col-xs-6 col-md-4'><a href="https://www.mogo.ca/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.mogo.ca</a></div>
                                        <div className='col-xs-6 col-md-4'><a href="https://www.equifax.ca/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.equifax.ca</a></div>
                                        <div className='col-xs-6 col-md-4'><a href="https://www.transunion.ca" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.transunion.ca</a></div>
                                    </div>
                                </div><br /> */}

                                            {/* <div
                                    style={{ border: "2px solid #A94442" }}
                                    className="row m-1 d-flex rounded p-3 bg-white">
                                    <p className='p-1'>
                                        <img style={{ width: "15px" }} src={img} alt='squareone' />
                                        <span
                                            style={{ color: "#A94442" }}
                                            className='px-1 py-2'>All units require tenant(s) to have an insurance policy at move-in for all leaseholders. If you don't already have an insurance provider, feel free to use our unique link below for a discounted policy: <a href={'https://www.squareone.ca'}>https://www.squareone.ca</a></span>
                                    </p>
                                </div><br />
                                <div
                                    style={{ border: "2px solid #A94442" }}
                                    className="row m-1 d-flex rounded p-3 bg-white">
                                    <p className='p-1'>
                                        <img style={{ width: "15px" }} src={img} alt='' />
                                        <span
                                            style={{ color: "#A94442" }}
                                            className='px-1 py-2'>Please note that all applicants must view the unit in order to process your application. A deposit will be required upon approval of application and instructions will be shared with you at that time.</span>
                                    </p>
                                </div> */}

                                            <div className='row border rounded-top'>
                                                <div
                                                    style={{ cursor: "pointer", border: "1px solid red" }}
                                                    onClick={() => handleCollapse('importantNote1')}
                                                    className="form-content p-2 d-flex justify-content-between rounded">
                                                    <div>
                                                        <h4 className='py-2 text-danger'>Important Note</h4>
                                                    </div>
                                                    <div>
                                                        <h2
                                                            onClick={() => handleCollapse('importantNote1')}
                                                            style={{ cursor: "pointer", color: "#A94442" }} className=''>
                                                            {
                                                                importantNote1 ? ("−") : ("+")
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse in={importantNote1}>
                                                <div style={{ backgroundColor: "white" }} className="row p-2 border-2 rounded ">
                                                    <div className="h-auto mb-2">
                                                        <div
                                                            style={{ border: "2px solid #8a6d3b" }}
                                                            className="row m-1 d-flex rounded p-3 bg-white">
                                                            <p className='p-1'>
                                                                <img style={{ width: "15px" }} src={img} alt='' />
                                                                <span
                                                                    style={{ color: "#8a6d3b" }}
                                                                    className='px-1 py-2'>All units require tenant(s) to have an insurance policy at move-in for all leaseholders. If you don't already have an insurance provider, feel free to use our unique link below for a discounted policy : <a href={'https://www.squareone.ca'}>https://www.squareone.ca</a></span>
                                                            </p>
                                                        </div><br />
                                                    </div>
                                                    <div className="h-auto mb-2">
                                                        <div
                                                            style={{ border: "2px solid #A94442" }}
                                                            className="row m-1 d-flex rounded p-3 bg-white">
                                                            <p className='p-1'>
                                                                <img style={{ width: "15px" }} src={img} alt='' />
                                                                <span
                                                                    style={{ color: "#A94442" }}
                                                                    className='px-1 py-2'> Please note that all applicants must view the unit in order to process your application. A deposit will be required upon approval of application and instructions will be shared with you at that time.</span>
                                                            </p>
                                                        </div><br />
                                                    </div>

                                                </div>
                                            </Collapse>
                                            <br />
                                            {/* <div className='row border rounded-top'>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleCollapse('importantNote2')}
                                        className="form-content p-2 d-flex justify-content-between rounded">
                                        <div>
                                            <h4 className='py-2'>Important Note</h4>
                                        </div>
                                        <div>
                                            <h2
                                                onClick={() => handleCollapse('importantNote2')}
                                                style={{ cursor: "pointer" }} className=''>
                                                {
                                                    importantNote2 ? ("−") : ("+")
                                                }
                                            </h2>
                                        </div>
                                    </div>
                                </div> */}
                                            {/* <Collapse in={importantNote2}>
                                    <div style={{ backgroundColor: "white" }} className="row p-3 border-2 rounded ">
                                        <div className="col-md-12 h-auto mb-2">
                                            <div
                                                style={{ border: "2px solid #A94442" }}
                                                className="row m-1 d-flex rounded p-3 bg-white">
                                                <p className='p-1'>
                                                    <img style={{ width: "15px" }} src={img} alt='' />
                                                    <span
                                                        style={{ color: "#A94442" }}
                                                        className='px-1 py-2'>Please note that all applicants must view the unit in order to process your application. A deposit will be required upon approval of application and instructions will be shared with you at that time.</span>
                                                </p>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </Collapse> */}
                                            <div style={{ backgroundColor: "white" }} className='form-content p-3 rounded my-4'>
                                                {/* <h1>General <span className='blue'>Application</span></h1> */}
                                                <div className='my-3'>
                                                    <div className="form-group my-3">
                                                        <label className='py-2 fw-bold' htmlFor="exampleInputEmail1">Preferred Move in Date</label>
                                                        <input
                                                            type="date"
                                                            onChange={handleChange}
                                                            value={formData.preferredMoveInDate}
                                                            name='preferredMoveInDate'
                                                            className="form-control required-number required-number2"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter email" />
                                                    </div>
                                                    <div className="form-group my-3">
                                                        <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Please specify the property <span style={{ backgroundColor: "#fcf8e3" }}>ADDRESS & UNIT # </span> you're interested in:</label>
                                                        <textarea
                                                            className='form-control required-number required-number2'
                                                            placeholder='Property address' name="propertyAddress"
                                                            value={formData.propertyAddress}
                                                            onChange={handleChange}
                                                            id="propertyAddress" >

                                                        </textarea>
                                                    </div>
                                                    <div className="form-group my-3">
                                                        <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Offered Rent Amount <small style={{ color: "rgba(1,1,1,0.4)", fontSize: "11px" }}> (optional) </small></label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text rounded-top">$</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name='offeredRentAmount'
                                                                onChange={handleChange}
                                                                value={formData.offeredRentAmount}
                                                                className="form-control required-number required-number2"
                                                                id="inlineFormInputGroup" placeholder="0.00" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group my-3">
                                                        <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Have you viewed the unit?</label><br />
                                                        <Form className='d-flex'>
                                                            <Form.Check
                                                                className='me-1'
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isOfferedRentAmountChecked}
                                                                onChange={handleOfferedRentAmountSwitchChange}
                                                            /> {youViewedisOn ? 'Yes' : 'No'}
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className='row border rounded-top'>
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleCollapse('personalInfo')}
                                                    className="form-content p-2 d-flex justify-content-between rounded">
                                                    <div>
                                                        <h4 className='py-2'>Personal Information</h4>
                                                    </div>
                                                    <div>
                                                        <h2
                                                            onClick={() => handleCollapse('personalInfo')}
                                                            style={{ cursor: "pointer" }} className=''>
                                                            {
                                                                personalInfo ? ("−") : ("+")
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse in={personalInfo}>
                                                <div style={{ backgroundColor: "white" }} className="row p-sm-1 p-md-2 border-2 rounded-bottom ">
                                                    <div className="col-sm-12 col-md-6 h-auto mb-2">
                                                        <label htmlFor="validationCustom01" className='py-2'><b>Required number of parking spaces?</b></label>
                                                        <div className="form-group-inline mt-1">
                                                            <select
                                                                onChange={handleChange}
                                                                className="w-100 required-number required-number2"
                                                                style={{}} id="parkingspaces"
                                                                value={formData.requiredNumberOfParkingSpaces}
                                                                name="requiredNumberOfParkingSpaces">
                                                                <option value=""> ---select---</option>
                                                                <option value="0">None</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10+">10+</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <label htmlFor="validationCustom02" className='py-2 fw-bold'>Family size to occupy home</label>
                                                        <div className="form-group-inline d-flex w-100 mt-1">
                                                            <div className="col ms-1 d-flex">
                                                                <select
                                                                    className="required-number required-number2 col-6"
                                                                    value={formData.familySizeToOccupyHomeAdults}
                                                                    onChange={handleChange}
                                                                    name="familySizeToOccupyHomeAdults"
                                                                    id="familySizeToOccupyHomeAdults">
                                                                    <option value=""></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                </select>
                                                                <label className='p-2' htmlhtmlFor="">Adults</label>
                                                            </div>
                                                            <div className="col d-flex">
                                                                <select className="required-number required-number2 col-6"
                                                                    value={formData.familySizeToOccupyHomeChildrens}
                                                                    name="familySizeToOccupyHomeChildrens"
                                                                    onChange={handleChange}
                                                                    id="familySizeToOccupyHomeChildrens">
                                                                    <option value=""></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                </select>
                                                                <label className='p-2' htmlhtmlFor="">Childrens</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group my-1">
                                                        <label className='py-2' htmlFor="exampleInputPassword1"><b>Do you own any pets?</b></label><br />
                                                        <Form>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isDoyouOwnAnyChecked}
                                                                onChange={handleDoyouOwnAnySwitchChange}
                                                            /> {youOwnisOn ?
                                                                <div className='YesNoDropdown'>
                                                                    <label className='YesNoButton'>Yes</label>
                                                                    <br />
                                                                    <div className="petsForm-Collapse">
                                                                        <div className="d-flex">
                                                                            <div className="form-group w-auto">
                                                                                <select class="required-number required-number2" id="NumberOfDogs" name="NumberOfDogs">
                                                                                    <option value="0"></option>
                                                                                    <option value="0">0</option>
                                                                                    <option value="1">1</option>
                                                                                    <option value="2">2</option>
                                                                                    <option value="3">3</option>
                                                                                    <option value="4">4</option>
                                                                                    <option value="5">5</option>
                                                                                    <option value="6">6</option>
                                                                                    <option value="7">7</option>
                                                                                    <option value="8">8</option>
                                                                                    <option value="9">9</option>
                                                                                    <option value="10">10+</option>
                                                                                </select>
                                                                                <label htmlFor="NumberOfDogs" className="ms-2">Dogs</label>
                                                                            </div>
                                                                            <div className="form-group ms-5">
                                                                                <select class="required-number required-number2" id="NumberOfCats" name="NumberOfCats">
                                                                                    <option value="0"></option>
                                                                                    <option value="0">0</option>
                                                                                    <option value="1">1</option>
                                                                                    <option value="2">2</option>
                                                                                    <option value="3">3</option>
                                                                                    <option value="4">4</option>
                                                                                    <option value="5">5</option>
                                                                                    <option value="6">6</option>
                                                                                    <option value="7">7</option>
                                                                                    <option value="8">8</option>
                                                                                    <option value="9">9</option>
                                                                                    <option value="10">10+</option>
                                                                                </select>
                                                                                <label htmlFor="NumberOfDogs" className="ms-2">Cats</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2">
                                                                            <div className="text-dark">Please list any other pets you own <sub><small style={{ color: 'grey' }}>(optional)</small></sub></div>
                                                                            <div className="mt-2 w-100">
                                                                                <textarea name="PleaseListAny" id="PleaseListAny" className='w-100 required-number required-number2' style={{ width: "100%" }}></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <div className='YesNoDropdown'>
                                                                    <div className='YesNoButton'>No</div>
                                                                </div>
                                                            }
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <br />
                                            <div className='row border rounded-top'>
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleCollapse('RHCL')}
                                                    className="form-content p-2 d-flex justify-content-between rounded">
                                                    <div>
                                                        <h4 className='py-2'>Rental History - Current Location</h4>
                                                    </div>
                                                    <div>
                                                        <h2
                                                            onClick={() => handleCollapse('RHCL')}
                                                            style={{ cursor: "pointer" }} className=''>
                                                            {
                                                                RHCL ? ("−") : ("+")
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse in={RHCL}>
                                                <div style={{ backgroundColor: "white" }} className="row mb-4 rounded p-3">
                                                    <div className="form-group mb-4">
                                                        <select className="w-100 rounded py-2 px-1 required-number"
                                                            id="CurrentLocationStatus"
                                                            onChange={handleChange}
                                                            value={formData.currentLocation}
                                                            name="currentLocation">
                                                            <option value="">Please specify...</option>
                                                            <option value="I am currently a home owner">I am currently a home owner</option>
                                                            <option value="I currently live with family">I currently live with family</option>
                                                            <option value="renting">I am currently renting</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <br />
                                            <div className='row border rounded-top'>
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleCollapse('RHPL')}
                                                    className="form-content p-2 d-flex justify-content-between rounded">
                                                    <div>
                                                        <h4 className='py-2'>Rental History - Past Location</h4>
                                                    </div>
                                                    <div>
                                                        <h2
                                                            onClick={() => handleCollapse('RHPL')}
                                                            style={{ cursor: "pointer" }} className=''>
                                                            {
                                                                RHPL ? ("−") : ("+")
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse in={RHPL}>
                                                <div style={{ backgroundColor: "white" }} className="row pb-4 p-3 rounded">
                                                    <div className="">
                                                        If you rented in the past 3 years at a location other than your current location, please specify below.
                                                    </div>

                                                    <div className="form-group">
                                                        <label className='py-1' htmlFor="PreviousAddress"><b>Previous address</b></label>
                                                        <input
                                                            className="form-control required-number required-number2"
                                                            id="PreviousAddress"
                                                            maxlength="255"
                                                            onChange={handleChange}
                                                            value={formData.previousAddress}
                                                            name="previousAddress" type="text" />
                                                    </div>
                                                    <div className="d-sm-block d-md-flex">
                                                        <div className="col-sm-12 col-md-5">
                                                            <div className="form-group">
                                                                <label className='py-1' htmlFor="email"><b>Previous Landlord's Phone</b></label>
                                                                <div className="input-group p-1">
                                                                    <span className="input-group-text">
                                                                        <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                            alt="" className='rentalHPlocation' />
                                                                    </span>
                                                                    <input
                                                                        className="form-control required-number required-number2"
                                                                        id="PreviousLandlordPhone" maxlength="50"
                                                                        value={formData.previousLandlordsPhone}
                                                                        onChange={handleChange}
                                                                        name="previousLandlordsPhone" type="text" />
                                                                </div>
                                                                <div className="error-msgs"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-7">
                                                            <label className='py-1' htmlFor="LandlordFirstName"><b>Previous Landlord's Name</b></label>
                                                            <div className="row p-1">
                                                                {/* <div className="row border">
                                                        <div className="col p-1">
                                                            <input className="form-control required-number required-number2" id="PreviousLandlordFirstName" maxlength="25" name="PreviousLandlordFirstName" placeholder="First" type="text" value="" />
                                                        </div>
                                                        <div className="col p-1">
                                                            <input className="form-control required-number required-number2" id="PreviousLandlordLastName" maxlength="25" name="PreviousLandlordLastName" placeholder="Last" type="text" value="" />
                                                        </div>
                                                    </div> */}
                                                                <div className="col-sm-12 col-md-6 pb-2">
                                                                    <input
                                                                        className="form-control required-number required-number2"
                                                                        value={formData.previousLandlordsFirstName}
                                                                        onChange={handleChange}
                                                                        id="previousLandlordsFirstName" maxlength="25"
                                                                        name="previousLandlordsFirstName" placeholder="First" type="text" />
                                                                </div>
                                                                <div className="col-sm-12 col-md-6">
                                                                    <input
                                                                        className="form-control required-number required-number2"
                                                                        id="PreviousLandlordLastName" maxlength="25"
                                                                        value={formData.previousLandlordsLastName}
                                                                        onChange={handleChange}
                                                                        name="previousLandlordsLastName" placeholder="Last" type="text" />
                                                                </div>
                                                                <div className="error-msgs"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group optional">
                                                        <label className='py-1' htmlFor="LandlordEmailAddress"><b className='fw-bold'>Previous Landlord's Email address</b> <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                        <input
                                                            className="form-control required-number required-number2"
                                                            id="PreviousLandlordEmailAddress" maxlength="80"
                                                            value={formData.previousLandlordsEmailAddress}
                                                            onChange={handleChange}
                                                            name="previousLandlordsEmailAddress"
                                                            placeholder="Enter email address" type="email" />
                                                        <div className="error-msgs"></div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <br />
                                            <div className='row border rounded-top'>
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleCollapse('Co-Signer')}
                                                    className="form-content p-2 d-flex justify-content-between rounded">
                                                    <div>
                                                        <h4 className='py-2'>Co-Signer</h4>
                                                    </div>
                                                    <div>
                                                        <h2
                                                            onClick={() => handleCollapse('Co-Signer')}
                                                            style={{ cursor: "pointer" }} className=''>
                                                            {
                                                                CoSigner ? ("−") : ("+")
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse in={CoSigner}>
                                                <div style={{ backgroundColor: "white" }} className="row rounded">
                                                    <div className="form-group my-3">
                                                        <label className='py-2' htmlFor="exampleInputPassword1"><b>Will there be a co-signer?</b></label><br />
                                                        <Form>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isWillThereBeaCosignerChecked}
                                                                onChange={handleWillThereBeaCosignerSwitchChange}
                                                            /> {therebeisOn ?
                                                                <div className='YesNoDropdown'>
                                                                    <label className='YesNoButton'>Yes</label>
                                                                    <br />
                                                                    <div className="mt-2">
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="App2FirstName" className='fw-bold'>Name</label>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6 mb-2">
                                                                                            <input
                                                                                                className="required-number required-number2 w-100"
                                                                                                id="Applicant_FirstName"
                                                                                                maxLength={25}
                                                                                                value={formData.firstName}
                                                                                                onChange={handleChange}
                                                                                                name="firstName"
                                                                                                placeholder="First"
                                                                                                type="text"
                                                                                                defaultValue=""
                                                                                                fdprocessedid="cqyqzj"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-sm-6 mb-2">
                                                                                            <input
                                                                                                className="required-number required-number2 w-100"
                                                                                                id="Applicant_LastName"
                                                                                                maxLength={25}
                                                                                                value={formData.lastName}
                                                                                                onChange={handleChange}
                                                                                                name="lastName"
                                                                                                placeholder="Last"
                                                                                                type="text"
                                                                                                fdprocessedid="v4zfp"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="error-msgs" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="birthday" className='fw-bold'>Date of Birth</label>
                                                                                    <div className="d-flex">
                                                                                        <div className="col mx-1">
                                                                                            <select
                                                                                                className="w-100 rounded-1 required-number required-number2"
                                                                                                id="Applicant_BirthYear"
                                                                                                value={birthYear}
                                                                                                onChange={(e) => setBirthYear(e.target.value)}
                                                                                                name="Applicant.BirthYear"
                                                                                                fdprocessedid="k0xgaq"
                                                                                            >
                                                                                                <option value="">YY</option>
                                                                                                {
                                                                                                    data.map((y, index) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <option key={index} value={y}>{y}</option>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="col mx-1">
                                                                                            <select
                                                                                                className="w-100 rounded-1 required-number required-number2"
                                                                                                id="Applicant_BirthMonth"
                                                                                                value={birthMonth}
                                                                                                onChange={(e) => setBirthMonth(e.target.value)}
                                                                                                name="Applicant.BirthMonth"
                                                                                                fdprocessedid="tqtrbk"
                                                                                            >
                                                                                                <option value="">MM</option>
                                                                                                {
                                                                                                    month.map((name, index) => {
                                                                                                        return <><option value={index + 1}>{name}</option></>
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="col mx-1">
                                                                                            <select
                                                                                                className="w-100 rounded-1  required-number required-number2"
                                                                                                id="Applicant_BirthDay"
                                                                                                value={birthDay}
                                                                                                onChange={(e) => setBirthDay(e.target.value)}
                                                                                                name="Applicant.BirthDay"
                                                                                                fdprocessedid="a6p3i"
                                                                                            >
                                                                                                <option value="">DD</option>
                                                                                                {
                                                                                                    d.map((d, index) => {
                                                                                                        return <><option key={index} value={d}>{d}</option></>
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="error-msgs" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="HomePhone" className='fw-bold'>Home Phone</label>
                                                                                    <div className="input-group">
                                                                                        <span className="input-group-text">
                                                                                            <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                                                alt="" className='rentalHPlocation' />
                                                                                        </span>
                                                                                        <input
                                                                                            className="form-control required-number required-number2 phone-group"
                                                                                            id="Applicant_Phone"
                                                                                            maxLength={30}
                                                                                            value={formData.homePhone}
                                                                                            onChange={handleChange}
                                                                                            name="homePhone"
                                                                                            placeholder="(555)-555-5555"
                                                                                            type="text"
                                                                                            defaultValue=""
                                                                                            fdprocessedid="9wti6s"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="error-msgs" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="CellPhone" className='fw-bold'>Cell Phone</label>
                                                                                    <div className="input-group">
                                                                                        <span className="input-group-text">
                                                                                            <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                                                alt="" className='rentalHPlocation' />
                                                                                        </span>
                                                                                        <input
                                                                                            className="form-control required-number required-number2 phone-group"
                                                                                            id="Applicant_CellPhone"
                                                                                            maxLength={30}
                                                                                            value={formData.cellPhone}
                                                                                            onChange={handleChange}
                                                                                            name="cellPhone"
                                                                                            placeholder="(555)-555-5555"
                                                                                            type="text"
                                                                                            defaultValue=""
                                                                                            fdprocessedid="j93t4d"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="error-msgs" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="form-group required mt-1">
                                                                                    <label htmlFor="email" className='fw-bold'>Email address</label>
                                                                                    <div>
                                                                                        <input
                                                                                            className="required-number required-number2 w-100"
                                                                                            id="Applicant_EmailAddress"
                                                                                            maxLength={80}
                                                                                            value={formData.emailAddress}
                                                                                            onChange={handleChange}
                                                                                            name="emailAddress"
                                                                                            placeholder="Enter email address"
                                                                                            type="email"
                                                                                            defaultValue=""
                                                                                            fdprocessedid="t4inew"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row" style={{ marginTop: 40 }}>
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="App2PlaceOfEmployment" className='fw-bold'>Place of Employment</label>
                                                                                    <div>
                                                                                        <input
                                                                                            className="required-number required-number2 w-100"
                                                                                            id="Applicant_PlaceOfEmployment"
                                                                                            value={formData.placeOfEmployment}
                                                                                            maxLength={80}
                                                                                            name="placeOfEmployment"
                                                                                            onChange={handleChange}
                                                                                            type="text"
                                                                                            defaultValue=""
                                                                                            fdprocessedid="nn00ff"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="App2Occupation" className='fw-bold'>Occupation</label>
                                                                                    <div>
                                                                                        <input
                                                                                            className="required-number required-number2 w-100"
                                                                                            id="Applicant_Occupation"
                                                                                            maxLength={80}
                                                                                            value={formData.occupation}
                                                                                            name="occupation"
                                                                                            onChange={handleChange}
                                                                                            type="text"
                                                                                            defaultValue=""
                                                                                            fdprocessedid="g89kda"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="email" className='fw-bold'>Length of employment</label>
                                                                                    <div className="d-flex">
                                                                                        <div className="col-xs-6 m-1 error-helper">
                                                                                            <div className="d-flex">
                                                                                                <input
                                                                                                    className="rounded col-6 required-number required-number2"
                                                                                                    id="Applicant_EmploymentLengthYears"
                                                                                                    maxLength={2}
                                                                                                    onChange={handleChange}
                                                                                                    value={formData.EmploymentLengthYears}
                                                                                                    name="EmploymentLengthYears"
                                                                                                    placeholder="YY"
                                                                                                    type="text"
                                                                                                    defaultValue=""
                                                                                                    fdprocessedid="49bouq"
                                                                                                />
                                                                                                <label className='m-1' htmlFor="email">Years</label>
                                                                                                <div className="error-msgs" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xs-6 m-1 error-helper">
                                                                                            <div className="d-flex">
                                                                                                <input
                                                                                                    className="rounded col-6 required-number required-number2"
                                                                                                    id="Applicant_EmploymentLengthMonths"
                                                                                                    maxLength={2}
                                                                                                    value={formData.EmploymentLengthMonths}
                                                                                                    name="EmploymentLengthMonths"
                                                                                                    onChange={handleChange}
                                                                                                    placeholder="MM"
                                                                                                    type="text"
                                                                                                    defaultValue=""
                                                                                                    fdprocessedid="oglyma"
                                                                                                />
                                                                                                <label className='m-1' htmlFor="email">Months</label>
                                                                                                <div className="error-msgs" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group optional">
                                                                                    <label htmlFor="App2EmployerPhone" className='fw-bold'>Employer's Telephone <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                                                    <div>
                                                                                        <input
                                                                                            className="required-number required-number2 w-100 mt-1"
                                                                                            id="Applicant_EmployerPhone"
                                                                                            maxLength={80}
                                                                                            value={formData.employersTelephone}
                                                                                            name="employersTelephone"
                                                                                            onChange={handleChange}
                                                                                            type="text"
                                                                                            fdprocessedid="uiifit"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="App2HourlyWage" className='fw-bold'>Hourly Wage <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                                                    <div className="input-group mb-3">
                                                                                        <div className="input-group-prepend">
                                                                                            <span className="input-group-text">$</span>
                                                                                        </div>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={formData.hourlyWage}
                                                                                            name='hourlyWage'
                                                                                            onChange={handleChange}
                                                                                            className="form-control required-number required-number2"
                                                                                            placeholder="0.00" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="App2GrossWage" className='fw-bold'>Gross Yearly</label>
                                                                                    <div className="input-group d-flex mb-3">
                                                                                        <div className="input-group-prepend">
                                                                                            <span className="input-group-text">$</span>
                                                                                        </div>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={formData.grossYearly}
                                                                                            name='grossYearly'
                                                                                            onChange={handleChange}
                                                                                            className="form-control required-number required-number2"
                                                                                            placeholder="0.00" />
                                                                                    </div>
                                                                                    <div className="error-msgs" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <div className='YesNoDropdown'>
                                                                    <div className='YesNoButton'>No</div>
                                                                </div>
                                                            }
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <br />
                                            <div className='row border rounded-top'>
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleCollapse('Other')}
                                                    className="form-content p-2 d-flex justify-content-between rounded">
                                                    <div>
                                                        <h4 className='py-2'>Other</h4>
                                                    </div>
                                                    <div>
                                                        <h2
                                                            onClick={() => handleCollapse('Other')}
                                                            style={{ cursor: "pointer" }} className=''>
                                                            {
                                                                Other ? ("−") : ("+")
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse in={Other}>
                                                <div style={{ backgroundColor: "white" }} className="row rounded">
                                                    <div className="form-group my-3">
                                                        <label className='py-2' htmlFor="exampleInputPassword1"><b>Additional Expectations</b></label><br />
                                                        <div className='pb-2'>
                                                            Please note, all units are rented 'as is'. Any alteration requests must be put in writing during the application process and will be submitted to the owner for approval. List any and all additional expectations that you feel need to be addressed prior to moving in. We will do our best to accommodate you, but we do not make any guarantees, whatsoever. Thank-you, The Management.
                                                        </div>
                                                        {/* <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={isAdditionalExpectationsChecked}
                                                    onChange={handleAdditionalExpectationsSwitchChange}
                                                />
                                            </Form> */}
                                                        <div className="mb-2 mt-2">
                                                            <textarea
                                                                value={formData.additionalExpectations}
                                                                name="additionalExpectations"
                                                                onChange={handleChange}
                                                                id="additionalExpectations"
                                                                cols="20" rows="3"
                                                                className='w-100 p-2 required-number required-number2'></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <div
                                                style={{ border: "2px solid #337ab7" }}
                                                className="row mt-4 d-flex rounded p-3 bg-white">
                                                <p className='p-1'>
                                                    {/* <img style={{ width: "15px" }} src={img} alt='' /> */}
                                                    <span
                                                        style={{ color: "#333", fontWeight: 'bold', lineHeight: 1.42857143, marginBottom: 5, }}
                                                    >To speed up the application process, we require every applicant that is over 18 years old to provide a full credit report, reflecting the score. Please see below for some free & paid websites to pull your own credit report and self-submit. Thank-you, The Management.</span>
                                                </p>
                                                <div className='row text-decoration-none' >
                                                    <div className='col-xs-6 col-md-4'><a href="https://www.creditkarma.ca/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.creditkarma.ca</a></div>
                                                    <div className='col-xs-6 col-md-4'><a href="https://borrowell.com/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.borrowell.com</a></div>
                                                    <div className='col-xs-6 col-md-4'><a href="https://www.mogo.ca/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.mogo.ca</a></div>
                                                    <div className='col-xs-6 col-md-4'><a href="https://www.equifax.ca/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.equifax.ca</a></div>
                                                    <div className='col-xs-6 col-md-4'><a href="https://www.transunion.ca" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.transunion.ca</a></div>
                                                </div>
                                            </div><br />
                                            <div className='my-3'>
                                                <button
                                                    onClick={() => {
                                                        setIsSubmiting(true)
                                                        handleSubmit()
                                                    }
                                                    }
                                                    className='btn btn-primary'>Submit Application</button>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                            <KWFooter />
                        </>
                    )
                }

            </></>
    )
}

import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import './propertycard.css';
import img from '../../assets/no-image.png'
// import { LineWave } from 'react-loader-spinner';

import { useNavigate } from "react-router-dom";


const KWPropertyCard = ({ currentProperty, mapCard }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardHeight = mapCard ? '450px' : '540px';
  const navigate = useNavigate()
  console.log(currentProperty);
  return (
    <>
      <Card
        style={{ cursor: 'pointer', width: '100%', minHeight: cardHeight }}
        className={`mb-3 shadow ${isHovered ? 'animate__animated animate__pulse' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => navigate(`/listing/${currentProperty.Unit.Id}`)}
      >
        <Card.Img style={{ height: '240px', objectFit: 'cover' }}
          // onClick={() => navigate(`/listing/${currentProperty.Unit.Id}`)}
          src={currentProperty.Property.Files[0] ? currentProperty.Property.Files[0].Url : img} variant='top' />
        <Card.Body>
          <Card.Title>
            <h3
              // style={{ cursor: 'pointer' }}
              // onClick={() => navigate(`/listing/${currentProperty.Unit.Id}`)}
              className="text-primary">{currentProperty.Property.Name}</h3>
          </Card.Title>
          <Card.Text>
            {!mapCard ? currentProperty.Property.StructureDescription : ''}
            <h6 className="mt-2">
              <span className="text-primary">${currentProperty.Rent}</span> /month
            </h6>
          </Card.Text>
          <Card.Text>
            Address: {currentProperty.Property.Address.AddressLine1}, {currentProperty.Property.Address.City}, {currentProperty.Property.Address.State}{' '}
            {currentProperty.Property.Address.PostalCode}, {currentProperty.Property.Address.Country}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default KWPropertyCard;

import React, { useState, useEffect } from 'react'
import './contactus.css'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import config from '../../config.json'
import axios from 'axios'
import swal from 'sweetalert';

export const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [cotactData, setContactData] = useState('');

  useEffect(() => {
    setContactData({
      "recipientEmail": config.emails.contact_us.to,
      "cc": config.emails.contact_us.cc,
      "mailSubject": config.emails.contact_us.subject,
      "name": name,
      "email": email,
      "phone": phone,
      "message": message
    })
  }, [name, email, phone, message])

  const handleSubmit = () => {

    console.log(name)
    console.log(phone)
    console.log(email)
    console.log(message)

    if (name === '') {
      swal("!", "Name is require!", "warning");
    } else if (phone === '') {
      swal("!", "Phone number is require!", "warning");
    } else if (email === '') {
      swal("!", "Email is require!", "warning");
    } else if (message === '') {
      swal("!", "Your message is require!", "warning");
    } else {

      axios.post(`${config.apiUrl}/v1/mailsend/contactus`, cotactData).then((res) => {

        if (res.data.status === 200) {
          swal(res.data.res, res.data.res, "Thanks for contacting us. We will back to you shortly.");
          setName('')
          setEmail('')
          setPhone('')
          setMessage('')
        }
      })
    }
  }


  return (
    <>
      <KWNavbar />
      <Container className='mt-5 pt-5'>
        <Row>
          <Col sm={12} lg={8}>
            <div className='form-content'>
              <h1>Contact <span className='blue'>Us</span></h1>
              <p className='pe-lg-5'>With K-W Property Management Corp we make finding your new home easy!
                Contact us and we will assist you in finding not only a home for you but the right home for you. Whether you are looking for an apartment, condo, single dwelling home, semi detached, townhouse, executive suite or even a bachelor apartment we have plenty of selection to choose from.
                <br />  We will help you find a home that suits the needs and amenities that best fit your lifestyle. It’s free so don’t hesitate to contact us and let us know exactly what you are looking for.
                <br /> If by chance you do not see your idea of your next home on our website feel free to contact us and let us know what you are looking for.
                <br />If you would like more information about any of our properties, please contact us through any of the means listed below. Thank you for visiting our website.
                <h6 className='mt-2'>Our <span className='blue'>Mailing Address:</span> 541 Queen St S. Kitchener, ON N2G 1W9</h6>
                <h6>Phone <span className='blue'>Number:</span> 519-954-8082</h6>
                <h6>Email <span className='blue'>Address:</span> kwp@kwproperty.com</h6>
              </p>
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <Card className='p-3 form-card mt-4'>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control value={name} name='name' onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter your email" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control value={phone} type="phone" onChange={(e) => setPhone(e.target.value)} placeholder="Enter your phone number" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control value={message} onChange={(e) => setMessage(e.target.value)} as="textarea" placeholder="Enter your message" style={{ height: '200px' }} />
                </Form.Group>
                <Button onClick={handleSubmit} className='btn btn-primary'>Submit</Button>

              </Form>
            </Card>
          </Col>
        </Row>

      </Container>
      <KWFooter />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import { LineWave } from 'react-loader-spinner';
import config from '../../config.json'
import './properties.css'
// import { FaMapPin } from 'react-icons/fa'
import KWPropertyCard from '../../components/propertycard/propertycard'


export const Properties = () => {

  const [propertyData, setPropertyData] = useState([]);
  // my import
  const [unitType, setUnitType] = useState('');
  const [filterData, setFilterData] = useState();
  const [priceRange, setPriceRange] = useState('');
  const [city, setCity] = useState('');
  const [bedroomCount, setBedroomCount] = useState('');
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [propertyList, setPropertyList] = useState(null);


  const [checkedItems, setCheckedItems] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});

  const handleCheckboxChange = (data) => {
    setCheckboxStates((prevCheckboxStates) => ({
      ...prevCheckboxStates,
      [data]: !prevCheckboxStates[data],
    }));

    setCheckedItems((prevCheckedItems) =>
      checkboxStates[data]
        ? prevCheckedItems.filter((item) => item !== data)
        : [...prevCheckedItems, data]
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(config.apiUrl + '/v1/rentals', {
        const response = await fetch(config.apiUrl + '/v1/rentals/units/listings', {
          headers: {
            'xbuildiumclientid': config.apiKeys.buildiumClientId,
            'xbuildiumclientsecret': config.apiKeys.buildiumClientSecret
          },
        });
        if (response.ok) {
          // const data = await response.json();
          // setPropertyData(data);
          // const map = new window.google.maps.Map(document.getElementById("map"), {
          //   center: { lat: 0, lng: 0 },
          //   zoom: 4
          // });
          // var geocoder = new window.google.maps.Geocoder();
          // // Loop through the addresses and geocode them
          // data.forEach(function (unit) {
          //   console.log(unit)
          //   console.log('unit.Property.Address')
          //   console.log(unit.Property.Address)
          //   const address1 = unit.Property.Address.AddressLine1 + ',' + unit.Property.Address.AddressLine2 + ',' + unit.Property.Address.AddressLine3 + ',' + unit.Property.Address.City + ',' +
          //   unit.Property.Address.State + ',' +
          //   unit.Property.Address.Country + ' - ' +
          //   unit.Property.Address.PostalCode;

          //   geocoder.geocode({ address: address1 }, function (results, status) {
          //     if (status === "OK") {
          //       new window.google.maps.Marker({
          //         map: map,
          //         position: results[0].geometry.location,
          //         title: unit.Property.Address
          //       });
          //     } else {
          //       console.error( 
          //         "Geocode was not successful for the following reason: " + status
          //       );
          //     }
          //   });
          // });

          const data = await response.json();
          setPropertyData(data);

          // Create a new map centered on Canada
          const map = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: 56.1304, lng: -106.3468 }, // Coordinates for the approximate center of Canada
            zoom: 2
          });

          const geocoder = new window.google.maps.Geocoder();
          const bounds = new window.google.maps.LatLngBounds();

          data.forEach(function (unit) {
            const address1 = unit.Property.Address.AddressLine1 + ',' + unit.Property.Address.AddressLine2 + ',' + unit.Property.Address.AddressLine3 + ',' + unit.Property.Address.City + ',' +
              unit.Property.Address.State + ',' +
              unit.Property.Address.Country + ' - ' +
              unit.Property.Address.PostalCode;

            geocoder.geocode({ address: address1 }, function (results, status) {
              if (status === "OK") {
                const marker = new window.google.maps.Marker({
                  map: map,
                  position: results[0].geometry.location,
                  title: unit.Property.Address
                });

                bounds.extend(marker.getPosition());
                map.fitBounds(bounds);
              } else {
                console.error("Geocode was not successful for the following reason: " + status);
              }
            });
          });
        } else {
          throw new Error('Error fetching property data');
        }
      } catch (error) {
        console.error('Error fetching property data:', error);
      }
    };
    fetchData();
  }, []);

  // my api

  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = config.apiUrl + '/v1/rentals/units/listings/all';

    // Define the headers
    const headers = {
      'xbuildiumclientid': config.apiKeys.buildiumClientId,
      'xbuildiumclientsecret': config.apiKeys.buildiumClientSecret
    };

    fetch(apiUrl, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(responseData => {

        // console.log('responseData')
        // console.log(JSON.stringify(responseData))

        setPropertyList(responseData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);



  // filter api

  useEffect(() => {

    const apiUrl = config.apiUrl + '/v1/rentals/property/filters';
    const headers = {
      'xbuildiumclientid': config.apiKeys.buildiumClientId,
      'xbuildiumclientsecret': config.apiKeys.buildiumClientSecret
    };

    fetch(apiUrl, {
      method: 'GET',
      headers: headers
    }).then(response => response.json())
      .then(responseData => {
        setFilterData(responseData)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  });

  useEffect(() => {
    setPropertyData(filteredProperty)
  }, [filteredProperty])

  const onSubmit = () => {
    let filterConditions = []

    if (unitType) {
      if (unitType !== 'A') {
        const condition = item => item.Unit.propertyType === unitType;
        filterConditions.push(condition);
      }
    }

    if (priceRange) {
      if (priceRange !== 'A') {
        const [startString, endString] = priceRange.split('-');
        const minRent = parseInt(startString, 10);
        if (endString === '+') {
          // const condition = item => item.Unit.MarketRent >= minRent;
          const condition = item => item.Rent >= minRent;
          filterConditions.push(condition);
        } else {
          const maxRent = parseInt(endString, 10);
          // const condition = item => item.Unit.MarketRent >= minRent && item.Unit.MarketRent <= maxRent;
          const condition = item => item.Rent >= minRent && item.Rent <= maxRent;
          filterConditions.push(condition);
        }
      }
    }

    if (city) {
      if (city !== 'A') {
        const condition = item => item.Unit.Address.City === city;
        filterConditions.push(condition);
      }
    }

    if (bedroomCount) {
      if (bedroomCount !== 'A') {

        let bedroomCountArray = []

        if (bedroomCount === 'OneBed') {
          bedroomCountArray.push('OneBed')
        } else if (bedroomCount === 'TwoBed') {
          bedroomCountArray.push('TwoBed')
        } else if (bedroomCount === 'ThreeBed') {
          bedroomCountArray.push('ThreeBed')
        } else if (bedroomCount === '3 or more') {
          bedroomCountArray.push('ThreeBed')
          bedroomCountArray.push('FourBed')
        } else if (bedroomCount === '4 or more') {
          // bedroomCount.push('ThreeBed')
          bedroomCountArray.push('FourBed')
        } else {
          bedroomCountArray = []
        }

        if (bedroomCountArray && bedroomCountArray.length > 0) {
          const condition = item => bedroomCountArray.includes(item.Unit.UnitBedrooms);
          filterConditions.push(condition);
        }

      }
    }

    if (checkedItems && checkedItems.length > 0) {
      const condition = item => checkedItems.every(feature => item.Unit.Features.includes(feature));
      filterConditions.push(condition);
    }

    const filteredPropertyList = propertyList.filter(item => {
      return filterConditions.every(condition => condition(item));
    });

    setFilteredProperty(filteredPropertyList)
  }



  const collapseShow = () => {
    document.querySelector('#collapseHide').style.display = "block";
    document.querySelector('#collapseShow').style.display = "none";
    document.querySelector('#advance-collapse').style.display = "block";
  }

  const collapseHide = () => {
    document.querySelector('#collapseHide').style.display = "none";
    document.querySelector('#collapseShow').style.display = "block";
    document.querySelector('#advance-collapse').style.display = "none";
  }


  return (
    <>
      <KWNavbar />
      <Container className='mt-5 pt-5'>
        <Row>
          <Col id="map" sm={12} md={5} className='mt-4 col-sm-12 col-md-5 propertiesMap'></Col>
          <Col sm={12} md={7} className='mt-4 md-lg-0'>
            {/* <Form.Control placeholder='Search Address'></Form.Control> */}
            <div className="w-100 pt-4" id="advance-collapse" >
              <div className="advance-main-div">
                <div className="properties-map-select-div">
                  <select className='required-number required-number2 w-100'
                    value={unitType}
                    onChange={(e) => setUnitType(e.target.value)}
                    name="unitType"
                    id="UnitType">
                    <option value="A">All Unit Types</option>
                    {

                      filterData ? (

                        filterData?.propertyTypes.length > 0 ? (

                          filterData?.propertyTypes.map((data) => {
                            return (
                              <>
                                <option value={data.code}>{data.type}</option>
                              </>
                            )
                          })
                        ) : ''
                      ) : ''
                    }
                  </select>
                </div>
                <div className="properties-map-select-div">
                  <select className='required-number required-number2 w-100'
                    value={priceRange}
                    onChange={(e) => setPriceRange(e.target.value)}
                    name="priceRange"
                    id="PriceRange">
                    <option value="A">All Price Range</option>
                    {

                      filterData ? (

                        filterData?.priceRange.length > 0 ? (

                          filterData?.priceRange.map((data) => {
                            return (
                              <>
                                <option value={`${data.minPrice}-${data.maxPrice}`}>
                                  {`${data.maxPrice}` === '+' ? `${data.minPrice}${data.maxPrice}` : `${data.minPrice}-${data.maxPrice}`}
                                </option>
                              </>
                            )
                          })
                        ) : ''
                      ) : ''
                    }
                  </select>
                </div>
                <div className="properties-map-select-div">
                  <select className='required-number required-number2 w-100 mt-3'
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    name="city"
                    id="City">
                    <option value="A">Select City</option>
                    {

                      filterData ? (

                        filterData?.cities.length > 0 ? (

                          filterData?.cities.map((data) => {
                            return (
                              <>
                                <option value={data}>
                                  {data}
                                </option>
                              </>
                            )
                          })
                        ) : ''
                      ) : ''
                    }
                  </select>
                </div>
                <div className="properties-map-select-div">
                  <select className='required-number required-number2 w-100 mt-3'
                    value={bedroomCount}
                    onChange={(e) => setBedroomCount(e.target.value)}
                    name="bedroomCount"
                    id="BedroomCount">
                    <option value="A"># of Bedrooms</option>
                    {

                      filterData ? (

                        filterData?.numberOfBedRooms.length > 0 ? (

                          filterData?.numberOfBedRooms.map((data) => {
                            return (
                              <>
                                <option value={data.code}>
                                  {data.type}
                                </option>
                              </>
                            )
                          })
                        ) : ''
                      ) : ''
                    }
                  </select>

                </div>
              </div>
              <div className="properties-content2">
                <div className="browse-grid2">
                  {filterData?.features && filterData.features.length > 0 ? (
                    filterData.features.map((data) => (
                      <label key={data} className='ms-2'>
                        <input
                          type="checkbox"
                          checked={checkboxStates[data] || false}
                          onChange={() => handleCheckboxChange(data)}
                          className=' mt-3 form-check-input mx-2'
                          value={data}
                        />
                        <label className=''>{data.replace(/([A-Z])/g, ' $1')}</label>
                      </label>
                    ))
                  ) : <div className="d-flex justify-content-center">
                    <LineWave
                      height="200px"
                      width="300px"
                      color="#0586f0"
                      ariaLabel="line-wave"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>}
                </div>
              </div>
            </div>
            <div className='mt-4 d-flex justify-content-between'>
              <Button variant='outline-primary' className='me-2 mb-2 mb-md-0' size='lg' onClick={collapseShow} id="collapseShow">Advance Search</Button>
              <Button variant='outline-primary' className='me-2 mb-2 mb-md-0' size='lg' onClick={collapseHide} id="collapseHide" >Cancel</Button>
              <button onClick={onSubmit} className='btn btn-primary'>Search</button>
            </div>
            <Row className='properties-container mt-4'>
              {
                propertyData.length > 0 ? (
                  propertyData.map((property) => (
                    <Col sm={12} lg={6}><KWPropertyCard mapCard={true} currentProperty={property} /></Col>
                  ))
                ) : (
                  <div className="d-flex justify-content-center">
                    <LineWave
                      height="200px"
                      width="300px"
                      color="#0586f0"
                      ariaLabel="line-wave"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      firstLineColor=""
                      middleLineColor=""
                      lastLineColor=""
                    />
                  </div>
                )
              }
            </Row>
          </Col>
        </Row>
      </Container>
      <KWFooter />
    </>
  )
}

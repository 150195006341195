import React from 'react'
import { Container, Nav } from 'react-bootstrap'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import logo from '../../assets/logo_white.png'
import './footer.css'

const KWFooter = () => {
    return (
        <div className='footer'>
            <div className="footer-container">
                <div className="footer-row">
                    <div className='footer-left'>
                        {/* <Nav defaultActiveKey="/home" className="flex-column">
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 1</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 2</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 3</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 4</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 4</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 4</Nav.Link>
                        </Nav> */}
                        <img src={logo} alt="" className='mt-2 footer-img' />
                    </div>
                    <div className='footer-right'>
                        <div className="footer-right2">
                            <h1 className='text-primary me-2'>___</h1>
                            <Nav defaultActiveKey="/home" className='pb-2'>
                                <a className='text-primary footer-icon' href="https://www.facebook.com/kwproperty/" ><FaFacebook size={25} /></a>
                                <a className='text-primary footer-icon' href="https://twitter.com/i/flow/login?redirect_after_login=%2FKWPropertyM" ><FaTwitter size={25} /></a>
                                <a className='text-primary footer-icon' href="https://www.instagram.com/kwproperty/" ><FaInstagram size={25} /></a>
                            </Nav>
                            <p className="text-dark lead mt-1">Copyright © 2023 KWProperty Inc.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KWFooter
import React, { useEffect, useState } from 'react'
import KWNavbar from "../../components/navbar/navbar";
import KWFooter from "../../components/footer/footer";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import config from '../../config.json';
import "./listing.css";
import "./PropertyView.css";

import Modal from "react-bootstrap/Modal";
import Carousel from 'react-bootstrap/Carousel';

import samplePic from '../../assets/no-image.png'
import camera from '../../assets/icons8-compact-camera-50.png'
import { FaCheck } from "react-icons/fa";

export default function PropertyView() {
    const [modalShow, setModalShow] = React.useState(false);
    const [listing, setListing] = useState();
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    const navigate = useNavigate()
    useEffect(() => {
        fetch(config.apiUrl + `/v1/rentals/units/${id}/listing`, {
            headers: {
                'xbuildiumclientid': config.apiKeys.buildiumClientId,
                'xbuildiumclientsecret': config.apiKeys.buildiumClientSecret
            },
        }).then(async (resp) => {
            if (resp.ok) {
                const data = await resp.json();
                return data
            }
        }).then((data) => {
            setListing(data);
        })
    }, [id])

    const handleApplyOnline = () => {

        console.log('Unit Type : ' + listing?.Unit?.unitType)

        if (listing?.Unit?.unitType === 'S') {
            navigate(`/student-application`)
        } else if (listing?.Unit?.unitType === 'R' || listing?.Unit?.unitType === 'C') {
            navigate(`/genaral-application`)
        }else{
            console.log('Unknown unit type.')
            navigate(`/genaral-application`)
        }
    }

    const handleNavigate = () => {
        navigate(`/ScheduleShowing?uid=${listing.Unit.Id}`)
    }

    return (
        <div>
            <KWNavbar />
            <Container className="p-5">
                <Row className="pt-5 mt-5">
                    <Col sm={12} lg={6}>
                        <h2>
                            {listing?.Property.Name}
                        </h2>
                        <h4 className="fw-normal mt-4">
                            {listing?.Unit.Description}
                        </h4>
                        <h5>K-W Property Management Corp Presents:</h5>
                        <h5 className="fw-normal mt-4">
                            Located at  {"  "}
                            <b>
                                {listing?.Unit?.Address?.AddressLine1},
                                {listing?.Unit?.Address?.AddressLine2},
                                {listing?.Unit?.Address?.City},
                                {listing?.Unit?.Address?.Country},
                                {listing?.Unit?.Address?.PostalCode},
                            </b>
                        </h5>
                        <h5 className="fw-normal mt-4">
                            {listing?.Property.StructureDescription}
                        </h5>
                        <h5 className="fw-normal mt-4">
                            To book an appointment check our online calendar for upcoming
                            showings{" "}
                            <a href="http://kwproperty.com/calendar.asp">
                                here
                            </a>
                            . If you do not find a suitable showing time, please email us your
                            availability and we will book you a time to see the unit.
                        </h5>
                        <h3 className="blue mt-5">${listing?.Rent}/month</h3>
                        <Button
                            onClick={handleApplyOnline}
                            className="btn btn-primary d-block mt-4 button-c">
                            Apply Online Today
                        </Button>
                        <Button
                            onClick={handleNavigate}
                            className="btn btn-primary d-block mt-4 button-c">
                            Book Appointment
                        </Button>
                    </Col>
                    {/* <Col sm={12} lg={6} className="ps-lg-5 mt-5 mt-lg-0">
                        <h5>Available:
                            {listing?.AvailableDate}
                        </h5>
                        <Image
                            src={listing?.Unit.Files.length ? listing.Unit.Files[0].Url : ""}
                            className="image-container mb-4 mb-lg-0"
                            fluid
                        />
                    </Col> */}


                    <Col sm={12} lg={6} className="ps-lg-5 mt-5 mt-lg-0 position-relative">
                        <h5>Available: {listing?.AvailableDate}</h5>
                        <Image
                            src={listing?.Unit.Files[0] ? listing?.Unit.Files[0]?.Url : samplePic}
                            className="image-container mb-4 mb-lg-0"
                            fluid
                        />
                        <button
                            onClick={() => setModalShow(true)}
                            className="seePhotoBtn">
                            <img className="p-1" src={camera} color="blue" alt="" width={30} />
                            See all photos</button>
                        <div className='w-100 Pdetails py-4 d-flex justify-content-around'>
                            <div>Bedrooms:
                                <span>{" " + listing?.Unit?.UnitBedrooms}</span>
                            </div>
                            <div>Bathrooms:
                                <span>{" " + listing?.Unit?.UnitBathrooms}</span>
                            </div>
                            <div>Sq Ft:
                                <span>{" " + listing?.Unit?.UnitSize}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    listing?.Unit?.Features?.length > 0 ? (
                        <>
                            <br />
                            <br />
                            <div className="content-row row">
                                <div className="col-12">
                                    <div className="panel features-panel">
                                        <div className="panel-title">Features</div>
                                        <div>
                                            <ul className="feature-list fa-ul no-bullets">
                                                {
                                                    listing?.Unit?.Features.map((data) => {
                                                        return (
                                                            <>
                                                                {/* <option value={data.code}>{data.type}</option> */}

                                                                <li className='Features-li'>
                                                                    <FaCheck className='text-success mx-2' />
                                                                    {data}
                                                                </li>
                                                            </>
                                                        )
                                                    })
                                                }

                                                <li className='Features-li'>
                                                    <FaCheck className='text-success mx-2' />
                                                    Pets Welcome
                                                </li>
                                                <li className='Features-li'>
                                                    <FaCheck className='text-success mx-2' />
                                                    Great Location
                                                </li>
                                                <li className='Features-li'>
                                                    <FaCheck className='text-success mx-2' />
                                                    Very Spacious
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (" ")
                }
                {
                    listing?.Unit?.Files?.length > 0 ? (
                        <>
                            <br />
                            <br />
                            <div className="content-row row">
                                <div className="col-12">
                                    <div className="panel features-panel">
                                        <div className="panel-title">More Photos</div>
                                        <div className='MorePhotos'>
                                            {
                                                listing?.Unit?.Files.map((data) => {
                                                    return (
                                                        <>
                                                            <div>
                                                                <img
                                                                    onClick={() => setModalShow(true)}
                                                                    src={data.Url}
                                                                    alt=""
                                                                    className='MorePhotos-Img'
                                                                    srcset="" />
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                        {/* <div>
                                            <img
                                                src={listing?.Unit.Files[0] ? listing?.Unit.Files[0]?.Url : samplePic}
                                                alt=""
                                                style={{ width: "100px", cursor: "pointer" }}
                                                srcset="" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : " "
                }

            </Container>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="center h-50 ">
                        <Carousel>
                            {
                                listing?.Unit.Files.length > 0 ? (
                                    listing?.Unit.Files.map((img, index) => {
                                        return (
                                            <Carousel.Item key={index}>
                                                <Image
                                                    src={img.Url}
                                                    className="image-containers mb-4 mb-lg-0"
                                                    fluid
                                                />
                                                <Carousel.Caption>
                                                    {/* <h3 className="tedxtblue">{listing?.Property.Name}</h3> */}
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        )
                                    })

                                ) : (
                                    <Carousel.Item>
                                        <Image
                                            onClick={() => setModalShow(true)}
                                            src={listing?.Unit.Files[0] ? listing?.Unit.Files[0]?.Url : samplePic}
                                            className="image-container mb-4 mb-lg-0"
                                            fluid
                                        />
                                        <Carousel.Caption>
                                            {/* <h3 className="tedxtblue">{listing?.Property.Name}</h3> */}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </div>
                </Modal.Body>
            </Modal>
            <KWFooter />
        </div>
    )
}

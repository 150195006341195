import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import './TenantNoticeTinancy.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { Collapse } from 'react-bootstrap'
import swal from 'sweetalert';
import { useEffect, useState } from 'react';
import config from '../../config.json'
import axios from 'axios';


export const TenentTenancy = () => {
    const [To, setTo] = useState('')
    const [From, setFrom] = useState('')
    const [AddressOf, setAddressOf] = useState('')
    const [TerminationDate, setTerminationDate] = useState('')
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [PhoneNumber, setPhoneNumber] = useState('')
    const [TerminationSignature, setTerminationSignature] = useState('')
    const handleCaptcha = (val) => {
        console.log(val)
    }

    const [TenentTermination, setTenentTermination] = useState({
        recipientEmail:config.emails.n9_form.to,
        cc: config.emails.n9_form.cc,
        recipientName: FirstName,
        LastName: LastName,
        ToLandLordsName: To,
        FromTenantName: From,
        AddressOf: AddressOf,
        TerminationDate: TerminationDate,
        PhoneNumber: PhoneNumber,
        Signature: TerminationSignature,
        SignatureDate: new Date().toLocaleString(),
        TenantCheck: " ",
        RepresentativeCheck: " ",
        fileNumber: " ",
        DeliveryMethod: " ",
        mailSubject: config.emails.n9_form.subject,
        templateData: {
            message: "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
            link1: "<link>"
        }
    });

    useEffect(() => {
        setTenentTermination({
            recipientEmail: " ",
            cc: config.emails.booking.cc,
            recipientName: FirstName,
            LastName: LastName,
            ToLandLordsName: To,
            FromTenantName: From,
            AddressOf: AddressOf,
            TerminationDate: TerminationDate,
            PhoneNumber: PhoneNumber,
            Signature: TerminationSignature,
            SignatureDate: new Date().toLocaleString(),
            TenantCheck: " ",
            RepresentativeCheck: " ",
            fileNumber: " ",
            DeliveryMethod: " ",
            mailSubject: config.emails.booking.subject,
            templateData: {
                message: "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
                link1: "<link>"
            }
        })
        console.log(window.history)
    }, [FirstName, TerminationDate, PhoneNumber, TerminationSignature, LastName])

    const SubmitTenentTermination = () => {
        if (To === '') {
            swal("!", "Landlords Name is required!", "warning");
        } else if (From === '') {
            swal("!", "Tenents Name is required!", "warning");
        } else if (AddressOf === '') {
            swal("!", "Address of the Rental Unit is required!", "warning");
        } else if (TerminationDate === '') {
            swal("!", "Termination Date is required!", "warning");
        } else if (FirstName === '') {
            swal("!", "First Name is required!", "warning");
        } else if (LastName === '') {
            swal("!", "Last Name is required!", "warning");
        } else if (PhoneNumber < 10) {
            swal("!", "Phone Number is required!", "warning");
        } else if (TerminationSignature === '') {
            swal("!", "Signature is required!", "warning");
        } else {
            console.log('TenentTermination')
            console.log(TenentTermination)
            axios.post(`${config.apiUrl}/v1/formcontroller/n9`,TenentTermination).then((res)=>{
                console.log(res)
            })
        }
    }

    const [ImportantInformation, setImportantInformation] = useState(false)

    const handleCollapse = (from) => {
        if (from === 'ImportantInformation') {
            setImportantInformation(!ImportantInformation)
        }
    }

    return (
        <>
            <KWNavbar></KWNavbar>
            <div className="container mt-5 pt-5">
                <div className="mt-5">
                    <div className="TenentTenancy-header mb-4">Tenant's Notice to End the Tenancy (N9)</div>
                    <div className="TenentTenancy-Box px-3 pt-2 pb-4">
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <label htmlFor="To" className='fw-bold mb-1'>To</label>
                                <input className='required-number required-number2 form-control'
                                    value={To}
                                    onChange={(e) => setTo(e.target.value)}
                                    type="text" name="To" id="To"
                                    placeholder='Landlords Name' />
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label htmlFor="From" className='fw-bold mb-1'>From</label>
                                <input className='required-number required-number2 form-control'
                                    value={From}
                                    onChange={(e) => setFrom(e.target.value)}
                                    type="text" name="From"
                                    id="From" placeholder='Tenents Name' />
                                <div style={{ opacity: "0.4" }}>Include all tenant names</div>
                            </div>
                        </div>
                        <div className="pt-2">
                            <label htmlFor="AddressOf" className='fw-bold mb-1'>Address of the Rental Unit :</label>
                            <input className='required-number required-number2 form-control'
                                value={AddressOf}
                                onChange={(e) => setAddressOf(e.target.value)}
                                type="text" name="AddressOf" id="AddressOf"
                                placeholder='Unit Address' />
                        </div>
                        <div className="pt-2">
                            <label htmlFor="AddressOf" className='fw-bold mb-1'>Termination date</label>
                            <input className='required-number required-number2 form-control'
                                value={TerminationDate}
                                onChange={(e) => setTerminationDate(e.target.value)}
                                type="date" name="TerminationDate" id="TerminationDate"
                                placeholder='Termination date' />
                        </div>
                    </div>

                    <div className="pt-4">
                        <div className="TenentTenancy-Box px-3 pt-2 pb-2 mt-2">
                            <div className=" justify-content-between d-flex"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCollapse('ImportantInformation')}
                            >
                                <div className="TenentTenancy-header2">Important Information from the Landlord and Tenant Board</div>
                                <div>
                                    <h2
                                        onClick={() => handleCollapse('ImportantInformation')}
                                        style={{ cursor: "pointer" }} className=''>
                                        {
                                            ImportantInformation ? ("−") : ("+")
                                        }
                                    </h2>
                                </div>
                            </div>
                            <Collapse in={ImportantInformation}>
                                <div className="px-3 pt-2 pb-4">
                                    <div className="TenentTenancy-Box-grid">
                                        <div className="TenentTenancy-Box-grid1">The termination date</div>
                                        <div className="TenentTenancy-Box-grid2">
                                            <div className="">
                                                For most types of tenancies (including monthly tenancies) the termination date must be at least <b className="text-dark">60 days</b> after the tenant gives the landlord this notice. Also, the termination date must be the last day of the rental period. For example, if the tenant pays on the first day of each month, the termination date must be the last day of the month. If the tenancy is for a fixed term (for example, a lease for one year), the termination date cannot be earlier than the last date of the fixed term.
                                            </div>
                                            <div className="mt-1">
                                                <b className='text-dark'>Exceptions:</b> <br />
                                                <div className=''>
                                                    A landlord can require a tenant to sign an N9 Tenant's Notice to End the Tenancy as a condition of agreeing to rent a rental unit in the following two situations:
                                                </div>
                                                <ul className="">
                                                    <li>
                                                        The termination date must at least <b>28 days</b> after the tenant gives the landlord this notice if the tenancy is daily or weekly (the tenant pays rent daily or weekly). Also, the termination date must be the last day of the rental period. For example, if the tenant pays rent weekly each Monday, the termination date must be a Sunday. If the tenancy is for a fixed term, the termination date cannot be earlier than the last date of the fixed term.
                                                    </li>
                                                    <li>
                                                        The termination date can be earlier than the last day of a fixed term tenancy (but still must be the last day of a rental period) if the tenant is giving this notice because:
                                                        <br />
                                                        <ul className="">
                                                            <li>the tenancy agreement was entered into on or after April 30, 2018,</li>
                                                            <li>the landlord was required to use the Residential Tenancy Agreement (Standard Form of Lease) form but did not,</li>
                                                            <li>the tenant demanded in writing that the landlord give them this form, and
                                                                <ul className="">
                                                                    <li>more than 21 days have passed since the tenant made their demand, and the landlord has not provided the form,</li>
                                                                    <b>OR</b>
                                                                    <li>the landlord provided the form less than 30 days ago but it was not signed by the tenant.</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        A special rule allows <b className="text-dark">less than 60 days' notice</b> in situations where the tenant would normally be required to give 60 days notice (for example, monthly tenancies). The tenant can give notice for the end of February no later than January 1st and can give notice for the end of March no later than February 1st.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <div className="TenentTenancy-Box-grid1">The landlord can apply to end the tenancy</div>
                                        <div className="TenentTenancy-Box-grid2">
                                            The landlord can apply to the Board for an order to end the tenancy and evict the tenant as soon as the tenant gives the landlord this notice. However, if the Board issues an order ending the tenancy, the order will not require the tenant to move out any earlier than the termination date the tenant included in this notice.
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <div className="TenentTenancy-Box-grid1">When a tenant can give 10 days' notice</div>
                                        <div className="TenentTenancy-Box-grid2">
                                            The termination date set out in this notice can be <b className="text-dark">10 days</b> (or more) after the tenant gives this notice to the landlord if the landlord has given the tenant either an <b className="text-dark">N12 Notice to End your Tenancy or an N13 Notice to End your Tenancy.</b> The termination date does not have to be the last day of a rental period.
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <div className="TenentTenancy-Box-grid1">Ending the tenancy when the landlord refused to allow the tenant to assign the rental unit</div>
                                        <div className="TenentTenancy-Box-grid2">
                                            <div>
                                                The tenant can use this notice to end the tenancy if the tenant asked the landlord for permission to assign the rental unit to someone else, and the landlord refused. The termination date must be:
                                            </div>
                                            <ul>
                                                <li>at least <b className='text-dark'>28 days</b> after the tenant gives the notice to the landlord if the tenancy is daily or weekly,</li>
                                                <li>at least <b className='text-dark'>30 days</b> after the tenant gives the notice to landlord if the tenancy is anything other than daily or weekly.</li>
                                            </ul>
                                            <div className='mt-1'>The termination date does not have to be the last day of a rental period or the last day of a fixed term.</div>
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <div className="TenentTenancy-Box-grid1">Ending the tenancy in a care home</div>
                                        <div className="TenentTenancy-Box-grid2">
                                            <div className="">If the tenant lives in a care home, the termination date in this notice can be 30 days (or more) after the tenant gives the notice to the landlord. The termination date does not have to be the end of a rental period or the last day of a fixed term.</div>
                                            <div className="mt-2">
                                                If a tenant who lives in a care home gives this notice to the landlord, they can also give the landlord a 10-day notice for the landlord to stop providing care services and meals. If the tenant gives the landlord the 10-day notice, the tenant is not required to pay for care services and meals after the end of the 10-day period.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <div className="TenentTenancy-Box-grid1">Tenants can't be required to sign this notice</div>
                                        <div className="TenentTenancy-Box-grid2">
                                            <div>
                                                A landlord cannot require the tenant to sign an N9 Tenant's Notice to End the Tenancy as a condition of agreeing to rent a unit. A tenant does not have to move out based on this notice if the landlord required the tenant to sign it when the tenant agreed to rent the unit.
                                            </div>
                                            <div className="mt-2">
                                                <b className="text-dark">Exceptions:</b> <br />
                                                <div>A landlord can require a tenant to sign an N9 Tenant's Notice to End the Tenancy as a condition of agreeing to rent a rental unit in the following two situations:</div>
                                                <ul className='mt-1'>
                                                    <li>The tenant is a student living in accommodation provided by a post-secondary institution or by a landlord who has an agreement with the post-secondary school to provide the accommodation.</li>
                                                    <li>
                                                        The tenant is occupying a rental unit in a care home for the purposes of receiving rehabilitative or therapeutic services, and
                                                        <ul>
                                                            <li>the tenant agreed to occupy the rental unit for not more than 4 years,</li>
                                                            <li>the tenancy agreement set out that the tenant can be evicted when the objectives of providing the care services have been met or will not be met, and</li>
                                                            <li>the rental unit is provided to the tenant under an agreement between the landlord and a service manager under the Housing Services Act, 2011</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TenentTenancy-Box-grid mt-2'>
                                        <div className="TenentTenancy-Box-grid1">The tenant must move out by the termination date</div>
                                        <div className="TenentTenancy-Box-grid2">
                                            The tenant must move out and remove all their personal possessions from the rental unit by the termination date set out on page 1. If the tenant moves out by the termination date set out above, but leaves behind personal possessions, the tenant will no longer have any rights to those possessions and the landlord will be allowed to dispose of them.
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <div className="TenentTenancy-Box-grid1">How to get more information</div>
                                        <div className="TenentTenancy-Box-grid2">
                                            For more information about this notice or your rights, you can contact the Landlord and Tenant Board. You can reach the Board by phone at <b className="text-dark">416-645-8080</b> or <b className="text-dark">1-888-332-3234</b>. You can visit the Board's website at <a href="" className='TenentTenancy-text-link'>tribunalsontario.ca/ltb</a>.
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className="TenentTenancy-Box px-4 pt-2 pb-4 mt-5">
                            <div>
                                <b className="text-dark">Signed by</b>
                                <div className="mt-2 d-flex">
                                    <div class="form-check me-3">
                                        <input type="radio" class="form-check-input" id="radio1" name="optradio" value="option1" checked />
                                        <label class="form-check-label" for="radio1">Tenant</label>
                                    </div>
                                    <div class="form-check ms-2">
                                        <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2" />
                                        <label class="form-check-label" for="radio2">Representative</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-sm-12 col-lg-6 mb-md-3 mb-lg-0">
                                    <label htmlFor="FirstName"><b>First name</b></label>
                                    <input className='required-number required-number2 form-control'
                                        value={FirstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        type="text" name="FirstName" id="FirstName"
                                        placeholder='First Name' />
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <label htmlFor="LastName"><b>Last name</b></label>
                                    <input className='required-number required-number2 form-control'
                                        value={LastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        type="text" name="LastName" id="LastName"
                                        placeholder='Last Name' />
                                </div>
                            </div>
                            <div className="pt-3">
                                <label htmlFor="numberInput"><b>Phone Number</b></label>
                                <div class="input-group">
                                    <span class="input-group-text input-group-text2"><img className='TenentTenancy-input-group-img' src="https://webstockreview.net/images/phone-icon-png-3.png" alt="" /></span>
                                    <input class="required-number required-number2 form-control"
                                        value={PhoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        type="text" id="PhoneNumber" step="1" />
                                </div>
                            </div>
                            <div className="pt-3">
                                <label htmlFor="TerminationSignature"><b>Signature</b></label>
                                <input className='required-number required-number2 form-control'
                                    value={TerminationSignature}
                                    onChange={(e) => setTerminationSignature(e.target.value)}
                                    type="text" name="TerminationSignature" id="TerminationSignature"
                                    placeholder='Signature' />
                            </div>
                        </div>
                        <div className="reChaptcha-div mt-4">
                            <div className='mb-3'>To protect this website from spam, please check the box below.</div>
                            <ReCAPTCHA
                                sitekey='6LdnSbYoAAAAABSGB3Ns7SgyQK2Nvn9dODuhzTR4'
                                onChange={handleCaptcha}
                            />
                            <div className="mt-3">
                                <button type="button" className='btn btn-primary' onClick={SubmitTenentTermination}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <KWFooter></KWFooter>
        </>
    )
}
